<template>
    <div :class="`m-3 wrapper  wrapper--${bu}`">
        <h3 class="text-center">Werkplaats Controlelijst</h3>
        <hr>
        <Loading v-if="loading" />
        <Tabs v-if="!loading">
            <Tab title="Controle lijst">
                <div class="p-2">
                    <WorkshopControlListTable :table_data="controle_list" />
                </div>
            </Tab>
            <Tab title="Aflever controle lijst">
                <div class="p-2">
                    <WorkshopControlListTable :table_data="controle_aflevering_list" />
                </div>
            </Tab>
        </Tabs>
    </div>
</template>

<script>
import WorkshopControlListTable from '../components/WorkshopControlListTable.vue';
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
    components: { WorkshopControlListTable, Loading, Tabs, Tab },
    data: () => ({
        controle_list: null,
        controle_aflevering_list: null,
        loading: null,
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            request(`controle-list`, "GET").then(({ controle_list, controle_aflevering_list }) => {
                this.controle_aflevering_list = controle_aflevering_list;
                this.controle_list = controle_list;
                this.loading = false;
            })
        }
    }
}

</script>

<template>
    <table :class="`w-100 table__border--default`">
      <thead :class="`table__head--default`">
              <th>Itemnummer</th>
              <th>Datum</th>
              <th>Klant</th>
              <th>Omschrijving</th>
              <th>Verkoper</th>
              <th>Kenteken</th>
              <th>WoStatus</th>
              <th>ExStatus</th>
      </thead>
      <tbody>
          <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
              <td><ImageHover :itemnummer="item.Itemnummer" :bu="table_style" /></td>
              <td>{{ item.datum }}</td>
              <td>{{ item.Klant }}</td>
              <td>{{ item.Omschrijving }}</td>
              <td>{{ item.Verkoper }}</td>
              <td>{{ item.Kenteken }}</td>
              <td>{{ item.WoStatus }}</td>
              <td>{{ item.ExStatus }}</td>
          </tr>
      </tbody>
  </table>
</template>
<script>
import ImageHover from "@/components/ImageHover.vue";

export default {
    components: {ImageHover},
    props: {
    table_data: [Object, Array],
  },
}
</script>